import { useEffect, useState } from 'react';
import Image from 'next/image';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import { Container } from './Container';
import backgroundImage from '../../images/landing-page/background-features.jpg';
import screenshotExpenses from '../../images/landing-page/screenshots/expenses.png';
import screenshotPayroll from '../../images/landing-page/screenshots/payroll.png';
import screenshotReporting from '../../images/landing-page/screenshots/reporting.png';
import screenshotVatReturns from '../../images/landing-page/screenshots/vat-returns.png';
import socialBlogIMG from '../../images/landing-page/screenshots/social-blog-wire.png';
import themeIMG from '../../images/landing-page/screenshots/theme-wire.png';
import collectionsIMG from '../../images/landing-page/screenshots/collections-wire.png';

const features = [
  {
    title: 'Payroll',
    description:
      "Keep track of everyone's salaries and whether or not they've been paid. Direct deposit not supported.",
    image: screenshotPayroll,
  },
  {
    title: 'Claim expenses',
    description:
      "All of your receipts organized into one place, as long as you don't mind typing in the data by hand.",
    image: screenshotExpenses,
  },
  {
    title: 'VAT handling',
    description:
      "We only sell our software to companies who don't deal with VAT at all, so technically we do all the VAT stuff they need.",
    image: screenshotVatReturns,
  },
  {
    title: 'Reporting',
    description:
      'Easily export your data into an Excel spreadsheet where you can do whatever the hell you want with it.',
    image: screenshotReporting,
  },
];

export function GridFeatures() {
  let [tabOrientation, setTabOrientation] = useState('horizontal');

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)');

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal');
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener('change', onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-white"
    >
      <Container className="relative mx-auto max-w-6xl border-x border-dashed border-gray-200 py-24 md:py-32 lg:py-48">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl font-medium tracking-tighter text-slate-900 sm:text-4xl md:text-5xl">
            Oh, and a few more things...
          </h2>
          <p className="mt-6 text-lg tracking-tightish text-slate-600">
            Endless customization, a home for everything you write, and more!
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex grid-cols-1 flex-col items-center justify-center rounded-2xl border border-slate-100 bg-slate-50">
            <Image
              src={socialBlogIMG}
              alt="Social Blog"
              width={333}
              height={197}
            />
            <div className="flex flex-col px-8 pb-8 pt-2">
              <p className="mb-2 text-base font-semibold text-slate-800">
                A home for everything you create
              </p>
              <p className="text-sm text-slate-500">
                Get a shareable, customizable home for everything you create
                online. From posts, products, links, and more.
              </p>
            </div>
          </div>
          <div className="flex grid-cols-1 flex-col items-center justify-center rounded-2xl border border-slate-100 bg-slate-50">
            <Image
              src={themeIMG}
              alt="Customize your style"
              width={333}
              height={197}
            />
            <div className="flex flex-col px-8 pb-8 pt-2">
              <p className="mb-2 text-base font-semibold text-slate-800">
                Customize your style
              </p>
              <p className="text-sm text-slate-500">
                Give your posts and home a custom color pallette, font pairing,
                and look.
              </p>
            </div>
          </div>
          <div className="flex grid-cols-1 flex-col items-center justify-center rounded-2xl border border-slate-100 bg-slate-50">
            <Image
              src={collectionsIMG}
              alt="Collections"
              width={333}
              height={197}
            />
            <div className="flex flex-col px-8 pb-8 pt-2">
              <p className="mb-2 text-base font-semibold text-slate-800">
                Organize content into collections
              </p>
              <p className="text-sm text-slate-500">
                Add posts to shareable, embedable, and customizable collections.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
