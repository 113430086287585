import { useEffect, useState, useRef } from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { TemplateCard } from '../TemplatesPage/CategoryResults';
import Link from 'next/link';

const features = [
  {
    id: 0,
  },
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export function GridInfinite({ templates }) {
  const [tab, setTab] = useState(0);

  const timer = useRef(null);

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (tab === 3) {
        setTab(0);
      } else {
        setTab(tab + 1);
      }
    }, 2000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [tab]);

  return (
    <section
      id="templates-grid"
      aria-label="Digital Writing templates for absolutely everything."
      className="relative overflow-hidden bg-slate-50"
    >
      <div className="relative mx-auto">
        <div className="absolute  top-1/2 left-1/2 z-20 flex w-full -translate-y-1/2 -translate-x-1/2 items-center justify-center">
          <div
            style={{
              backgroundImage:
                'radial-gradient(rgba(248, 250, 252, 1) 0%, rgba(248, 250, 252, 0) 100%)',
            }}
            className="px-8 py-32 lg:px-24"
          >
            <h2 className="mx-auto max-w-xl pb-4 text-center font-display text-3xl font-medium tracking-tighter text-orange-500 sm:text-4xl md:text-5xl">
              Digital Writing templates for absolutely everything.
            </h2>
          </div>
        </div>

        <Tab.Group
          as="div"
          className="flex w-full flex-col items-center justify-center space-y-6"
        >
          {({ selectedIndex }) => (
            <>
              <Tab.Panels className="relative flex w-full items-center justify-center overflow-hidden">
                <div className="absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-l from-transparent to-slate-50" />
                <div className="absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-r from-transparent to-slate-50" />
                <div className="absolute right-0 bottom-0 z-10 flex h-16 w-full items-center justify-center bg-gradient-to-b from-transparent to-white py-4">
                  <Link
                    href={'/templates'}
                    target={'_blank'}
                    className={` z-10 flex flex-row items-center justify-center space-x-2 rounded-full border-0 border-transparent bg-orange-500 px-4 py-1 text-sm font-medium text-white outline-none ring-0 duration-150 hover:scale-105 hover:bg-orange-600 active:scale-100`}
                  >
                    <p>See All Templates</p>
                    <ArrowNarrowRightIcon className="h-3 w-3" />
                  </Link>
                </div>
                <div className="absolute right-0 top-0 z-10 h-16 w-full bg-gradient-to-t from-transparent to-slate-50" />
                <div className=" mx-auto flex space-x-4 px-5 py-1 pb-8">
                  {features.map((feature, featureIndex) => (
                    <Tab.Panel
                      static
                      key={feature.name}
                      className={clsx(
                        ' -ml-4 w-full transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                        featureIndex !== tab ? 'opacity-100' : 'z-0',
                      )}
                      style={{ transform: `translateY(-${tab * 20}%)` }}
                      aria-hidden={featureIndex !== tab}
                    >
                      <div className="relative grid h-[600px] w-[1500px] grid-cols-6 gap-4">
                        {templates &&
                          templates.map((template, index) => (
                            <TemplateCard
                              packSlug={template.meta.pack}
                              packView={false}
                              modal={false}
                              landing={true}
                              template={template}
                              name={template.meta.title}
                              slug={template.meta.slug}
                              type={template.meta.type}
                              color={template.meta.color}
                              icon={template.meta.icon}
                              description={template.meta.description}
                              locked={true}
                              creator_name={template.meta.creator_name}
                              creator_photo={template.meta.creator_photo}
                              uses={template.meta.uses}
                              example={template.meta.example}
                            />
                          ))}
                      </div>
                    </Tab.Panel>
                  ))}
                </div>
                <div className="rounded-4xl pointer-events-none absolute inset-0" />
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </div>
    </section>
  );
}
