import Link from 'next/link';

export function NavLink({ href, click, children }) {
  return href ? (
    <Link
      href={href}
      className="inline-block rounded-lg py-1 px-2 text-sm font-medium text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </Link>
  ) : (
    <button
      onClick={click}
      className="inline-block rounded-lg py-1 px-2 text-sm font-medium text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </button>
  );
}
