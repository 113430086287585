import { useEffect, useState, useRef } from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import { Container } from './Container';
import elin from '../../images/landing-page/avatars/elin.jpg';
import {
  CheckIcon,
  EyeIcon,
  HeartIcon,
  LinkIcon,
} from '@heroicons/react/solid';

import { Testimonial } from './Testimonial';

const features = [
  {
    id: 0,
  },
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export function SideBySideCarosel() {
  const [tab, setTab] = useState(0);
  const [tabChanged, changeTabChanged] = useState(false);
  const [data, setData] = useState([]);

  const timer = useRef(null);

  useEffect(() => {
    if (tabChanged === false) {
      timer.current = setTimeout(() => {
        if (tab === 1) {
          setTab(0);
        } else {
          setTab(tab + 1);
        }
      }, 3000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [tab]);

  useEffect(() => {
    if (!tabChanged) return;

    clearTimeout(timer.current);
  }, [tabChanged, timer]);

  useEffect(() => {
    const newData = createData();

    setData(newData);
  }, []);

  const createData = () => {
    const data = [];
    for (let i = 0; i < 12; i++) {
      data.push({
        id: i,
        views: Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000,
        likes: Math.floor(Math.random() * (200 - 10 + 1)) + 10,
        shares: Math.floor(Math.random() * (200 - 10 + 1)) + 10,
        clicks: Math.floor(Math.random() * (100 - 0 + 1)) + 0,
      });
    }
    return data;
  };
  return (
    <section
      id="analytics"
      aria-label="Analytics to make sense of it all."
      className="relative overflow-hidden bg-slate-50"
    >
      <Container className="relative mx-auto flex max-w-6xl grid-cols-10 flex-col items-center justify-center gap-16 border-x border-dashed border-gray-200 py-24 md:py-32 lg:grid lg:py-48">
        <div className="col-span-4 flex max-w-2xl flex-col md:mx-auto md:text-left xl:max-w-none">
          <p className="mb-6 font-sans text-base font-semibold tracking-tight text-green-500">
            Analytics
          </p>

          <h2 className="font-display text-3xl font-medium tracking-tighter text-slate-900 sm:text-4xl md:text-5xl">
            Analytics to make sense of it all.
          </h2>
          <p className="mt-6 text-lg tracking-tightish text-slate-600">
            Digital Writers need to be data-driven. Typeshare helps you find the
            type of content your audience enjoys.
          </p>
          <div className="mt-6 flex flex-col space-y-1.5">
            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-green-500/10 p-1 text-green-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Engagement rate
              </p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-green-500/10 p-1 text-green-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">Likes</p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-green-500/10 p-1 text-green-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">Shares</p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-green-500/10 p-1 text-green-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">Views</p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-green-500/10 p-1 text-green-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Profile views
              </p>
            </div>
            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-green-500/10 p-1 text-green-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Link clicks
              </p>
            </div>
          </div>

          <Testimonial
            avatar={elin}
            name={'Elin Melaas'}
            theme={'light'}
            color={'green'}
            url={'https://twitter.com/ElinMelaas/status/1521461575017799681'}
            text={
              'Typeshare is easy to use, has fantastic templates, is inspiring, and has super cool analytics. Recommended! 😀'
            }
          />
        </div>

        <div className="col-span-6 flex w-full flex-col">
          <Tab.Group
            as="div"
            className="flex w-full flex-col items-center justify-center space-y-6"
          >
            {({ selectedIndex }) => (
              <>
                <Tab.Panels className="relative w-full overflow-hidden">
                  <div className="absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-l from-transparent to-slate-50" />
                  <div className="absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-r from-transparent to-slate-50" />
                  <div className="absolute right-0 bottom-0 z-10 flex h-16 w-full items-center justify-center bg-gradient-to-b from-transparent to-slate-50 py-4"></div>
                  <div className="absolute right-0 top-0 z-10 h-16 w-full bg-gradient-to-t from-transparent to-slate-50" />
                  <div className=" flex w-full space-x-4 px-5 py-1 pb-8">
                    {features.map((feature, featureIndex) => (
                      <Tab.Panel
                        static
                        key={feature.title}
                        className={clsx(
                          ' -ml-4 w-full transition duration-1000 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                          featureIndex !== tab ? 'opacity-100' : 'z-0',
                        )}
                        style={{ transform: `translateY(-${tab * 50}%)` }}
                        aria-hidden={featureIndex !== tab}
                      >
                        <div className="relative grid h-[600px] w-[500px] grid-cols-1 gap-4">
                          {data.length > 0 &&
                            data.map((item, index) => (
                              <div
                                key={feature.title + index}
                                className=" flex flex-row justify-between rounded-xl bg-white p-4 shadow-lg shadow-slate-900/5  ring-1 ring-slate-500/10 duration-300 hover:scale-105"
                              >
                                <div className="flex w-full flex-col space-y-2">
                                  <div className="h-4 w-2/3 rounded-full bg-slate-100" />
                                  <div className="flex flex-row space-x-2">
                                    <div className="flex flex-row items-center justify-center space-x-1 rounded-full bg-slate-100 px-2 py-1 text-xs font-medium text-slate-500">
                                      <EyeIcon className="h-3 w-3" />
                                      <p>
                                        {item.views
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ',',
                                          )}
                                      </p>
                                    </div>

                                    <div className="flex flex-row items-center justify-center space-x-1 rounded-full bg-slate-100 px-2 py-1 text-xs font-medium text-slate-500">
                                      <HeartIcon className="h-3 w-3" />

                                      <p>
                                        {item.likes
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ',',
                                          )}
                                      </p>
                                    </div>

                                    <div className="flex flex-row items-center justify-center space-x-1 rounded-full bg-slate-100 px-2 py-1 text-xs font-medium text-slate-500">
                                      <LinkIcon className="h-3 w-3" />

                                      <p>
                                        {item.clicks
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ',',
                                          )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {index % 4 === 0 ? (
                                  <div className="my-auto flex items-center justify-center whitespace-nowrap rounded-full bg-green-500/10 px-2 py-1 text-xs font-semibold text-green-500">
                                    <p>Top Performer</p>
                                  </div>
                                ) : index === 3 ? (
                                  <div className="my-auto flex items-center justify-center whitespace-nowrap rounded-full bg-yellow-500/10 px-2 py-1 text-xs font-semibold text-yellow-500">
                                    <p>Highly Engaging</p>
                                  </div>
                                ) : index === 6 ? (
                                  <div className="my-auto flex items-center justify-center whitespace-nowrap rounded-full bg-red-500/10 px-2 py-1 text-xs font-semibold text-red-500">
                                    <p>Under Performer</p>
                                  </div>
                                ) : null}
                              </div>
                            ))}
                        </div>
                      </Tab.Panel>
                    ))}
                  </div>
                  <div className="rounded-4xl pointer-events-none absolute inset-0" />
                </Tab.Panels>
              </>
            )}
          </Tab.Group>
        </div>
      </Container>
    </section>
  );
}
