import { Testimonial } from './Testimonial';
import { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import { Container } from './Container';

import evaMurray from '../../images/landing-page/avatars/evaMurray.jpg';
import { ArrowNarrowRightIcon, CheckIcon } from '@heroicons/react/solid';
import { motion } from 'framer-motion';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';
import { TemplateCard } from '../TemplatesPage/CategoryResults';
import Link from 'next/link';

const features = [
  {
    id: 0,
  },
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
];

const tmpArray = [0, 1, 2, 3, 4, 5];

export function SideBySide({ templates }) {
  const [tab, setTab] = useState(0);
  const [tabChanged, changeTabChanged] = useState(false);

  const [essayTemplates, setEssayTemplates] = useState([]);
  const [threadTemplates, setThreadTemplates] = useState([]);
  const [linkedInTemplates, setLinkedInTemplates] = useState([]);
  const [tweetTemplates, setTweetTemplates] = useState([]);

  useEffect(() => {
    if (templates) {
      setEssayTemplates(templates.essays);
      setThreadTemplates(templates.threads);
      setLinkedInTemplates(templates.linkedin);
      setTweetTemplates(templates.tweets);
    }
  }, [templates]);

  const timer = useRef(null);

  useEffect(() => {
    if (tabChanged === false) {
      timer.current = setTimeout(() => {
        if (tab === 3) {
          setTab(0);
        } else {
          setTab(tab + 1);
        }
      }, 3000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [tab]);

  useEffect(() => {
    if (!tabChanged) return;

    clearTimeout(timer.current);
  }, [tabChanged, timer]);

  const changeTab = (val) => {
    changeTabChanged(true);
    setTab(val);
  };

  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <section
      id="templates"
      aria-label="Never stare at a blank page again"
      className="relative -mt-40 overflow-hidden bg-slate-50"
    >
      <Container className="relative mx-auto flex max-w-6xl grid-cols-10 flex-col items-center justify-center gap-16 border-x border-dashed border-gray-200 pb-24 pt-32 md:pb-32 md:pt-48 lg:grid lg:pb-48 lg:pt-64">
        <div className="col-span-4 flex max-w-2xl flex-col md:mx-auto md:text-left xl:max-w-none">
          <p className="mb-6 font-sans text-base font-semibold tracking-tight text-orange-500">
            Templates
          </p>

          <h2 className="font-display text-3xl font-medium tracking-tighter text-slate-900 sm:text-4xl md:text-5xl">
            Never stare at a blank page again.
          </h2>
          <p className="mt-6 text-lg tracking-tightish text-slate-600">
            Our extensive library of expertly created Digital Writing templates
            are the best way to get started.
          </p>
          <div className="mt-6 flex flex-col space-y-1.5">
            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-orange-500/10 p-1 text-orange-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Twitter thread templates
              </p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-orange-500/10 p-1 text-orange-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Atomic Essay templates
              </p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-orange-500/10 p-1 text-orange-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                LinkedIn Post templates
              </p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-orange-500/10 p-1 text-orange-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Standalone tweet templates
              </p>
            </div>

            <div className="flex flex-row space-x-2">
              <div className="flex max-w-min items-center justify-center rounded-full bg-orange-500/10 p-1 text-orange-500">
                <CheckIcon className="h-3 w-3" />
              </div>
              <p className="font-sans text-[15px] text-slate-600">
                Subatomic essay templates
              </p>
            </div>
          </div>

          <Testimonial
            avatar={evaMurray}
            name={'Eva Murray'}
            bio={'@TriMyData'}
            url={'https://twitter.com/TriMyData/status/1549373003829575682'}
            theme={'light'}
            color={'orange'}
            text={
              'Typeshare templates have been incredibly useful for learning and improving my writing.\n\nI’ll be honest: I NEVER thought good writers ‘simply’ used templates. And it makes so much sense that they do, to help them create furiously.'
            }
          />
        </div>

        <div className="col-span-6 flex w-full flex-col">
          <Tab.Group
            as="div"
            className="hidden w-full flex-col items-center justify-center space-y-6 md:flex"
          >
            {({ selectedIndex }) => (
              <>
                <div className="grid grid-cols-4 items-center justify-center">
                  <Tab.List
                    className={`relative col-span-4 grid grid-cols-4 items-center justify-center`}
                  >
                    <motion.div
                      className={`absolute z-0 w-1/4 p-0  ${
                        tab === 0
                          ? 'left-0'
                          : tab === 1
                          ? 'left-1/4 -translate-x-1/4'
                          : tab === 2
                          ? 'left-1/2 -translate-x-1/2'
                          : 'right-0'
                      }`}
                      layout
                      transition={spring}
                    >
                      <div className=" h-[34px] w-full rounded-full bg-orange-500 " />
                    </motion.div>

                    <Tab
                      key={'templates'}
                      onClick={() => changeTab(0)}
                      className={` ${
                        tab === 0 ? ' text-white' : 'text-slate-600'
                      }  z-10 flex flex-row items-center justify-center space-x-2 rounded-lg border-0 border-transparent px-4 py-1 text-sm font-medium outline-none ring-0 duration-150`}
                    >
                      <p>Threads</p>
                    </Tab>

                    <Tab
                      key={'publishing'}
                      onClick={() => changeTab(1)}
                      className={` ${
                        tab === 1 ? 'text-white' : 'text-slate-600'
                      } z-10 flex flex-row items-center justify-center space-x-2 rounded-lg border-0 border-transparent px-4 py-1 text-sm  font-medium outline-none ring-0 duration-150`}
                    >
                      <p>Essays</p>
                    </Tab>

                    <Tab
                      key={'linkedin'}
                      onClick={() => changeTab(2)}
                      className={` ${
                        tab === 2 ? 'text-white' : 'text-slate-600'
                      } z-10 flex flex-row items-center justify-center space-x-2 rounded-lg border-0 border-transparent px-4 py-1 text-sm font-medium outline-none ring-0 duration-150`}
                    >
                      <p>LinkedIn</p>
                    </Tab>
                    <Tab
                      key={'tweets'}
                      onClick={() => changeTab(3)}
                      className={` ${
                        tab === 3 ? 'text-white' : 'text-slate-600'
                      } z-10 flex flex-row items-center justify-center space-x-2 rounded-lg border-0 border-transparent px-4 py-1 text-sm font-medium outline-none ring-0 duration-150`}
                    >
                      <p>Tweets</p>
                    </Tab>
                  </Tab.List>
                </div>

                <Tab.Panels className="relative w-full overflow-hidden">
                  <div className="absolute left-0 top-0 z-10 h-full w-16 bg-gradient-to-l from-transparent to-slate-50" />
                  <div className="absolute right-0 top-0 z-10 h-full w-16 bg-gradient-to-r from-transparent to-slate-50" />
                  <div className="absolute right-0 bottom-0 z-10 flex h-16 w-full items-center justify-center bg-gradient-to-b from-transparent to-slate-50 py-4">
                    <Link
                      href={'/templates'}
                      target={'_blank'}
                      className={` z-10 flex flex-row items-center justify-center space-x-2 rounded-full border-0 border-transparent bg-slate-900 px-4 py-1 text-sm font-medium text-white outline-none ring-0 duration-150 hover:scale-105 hover:bg-slate-800 active:scale-100`}
                    >
                      <p>See All Templates</p>
                      <ArrowNarrowRightIcon className="h-3 w-3" />
                    </Link>
                  </div>
                  <div className="absolute right-0 top-0 z-10 h-16 w-full bg-gradient-to-t from-transparent to-slate-50" />
                  <div className=" flex w-full space-x-4 px-5 py-1 pb-8">
                    {features.map((feature, featureIndex) => (
                      <Tab.Panel
                        static
                        key={feature.name}
                        className={clsx(
                          ' -ml-4 w-full transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                          featureIndex !== tab ? 'opacity-100' : 'z-0',
                        )}
                        style={{ transform: `translateX(-${tab * 94}%)` }}
                        aria-hidden={featureIndex !== tab}
                      >
                        <div className="relative grid h-[600px] w-[500px] grid-cols-2 items-stretch gap-4">
                          {
                            //if featureindex is 1, map the essaayTemplates ,if it is 0, map the threadTemplates
                            featureIndex === 1
                              ? essayTemplates.length === 0
                                ? tmpArray.map((tmp, index) => (
                                    <div className=" h-64 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 duration-300  hover:scale-105"></div>
                                  ))
                                : essayTemplates.map(
                                    (template, essayTemplateIndex) => (
                                      <TemplateCard
                                        packSlug={template.meta.pack}
                                        packView={false}
                                        modal={false}
                                        landing={true}
                                        template={template}
                                        name={template.meta.title}
                                        slug={template.meta.slug}
                                        type={template.meta.type}
                                        color={template.meta.color}
                                        icon={template.meta.icon}
                                        description={template.meta.description}
                                        locked={true}
                                        creator_name={
                                          template.meta.creator_name
                                        }
                                        creator_photo={
                                          template.meta.creator_photo
                                        }
                                        uses={template.meta.uses}
                                        example={template.meta.example}
                                      />
                                    ),
                                  )
                              : featureIndex === 0
                              ? threadTemplates.length === 0
                                ? tmpArray.map((tmp, index) => (
                                    <div className=" h-64 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 duration-300  hover:scale-105"></div>
                                  ))
                                : threadTemplates.map(
                                    (template, threadTemplateIndex) => (
                                      <TemplateCard
                                        packSlug={template.meta.pack}
                                        packView={false}
                                        modal={false}
                                        landing={true}
                                        template={template}
                                        name={template.meta.title}
                                        slug={template.meta.slug}
                                        type={template.meta.type}
                                        color={template.meta.color}
                                        icon={template.meta.icon}
                                        description={template.meta.description}
                                        locked={true}
                                        creator_name={
                                          template.meta.creator_name
                                        }
                                        creator_photo={
                                          template.meta.creator_photo
                                        }
                                        uses={template.meta.uses}
                                        example={template.meta.example}
                                      />
                                    ),
                                  )
                              : featureIndex === 2
                              ? linkedInTemplates.length === 0
                                ? tmpArray.map((tmp, index) => (
                                    <div className=" h-64 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 duration-300  hover:scale-105"></div>
                                  ))
                                : linkedInTemplates.map(
                                    (template, linkedinTemplateIndex) => (
                                      <TemplateCard
                                        packSlug={template.meta.pack}
                                        packView={false}
                                        modal={false}
                                        landing={true}
                                        template={template}
                                        name={template.meta.title}
                                        slug={template.meta.slug}
                                        type={template.meta.type}
                                        color={template.meta.color}
                                        icon={template.meta.icon}
                                        description={template.meta.description}
                                        locked={true}
                                        creator_name={
                                          template.meta.creator_name
                                        }
                                        creator_photo={
                                          template.meta.creator_photo
                                        }
                                        uses={template.meta.uses}
                                        example={template.meta.example}
                                      />
                                    ),
                                  )
                              : tweetTemplates.length === 0
                              ? tmpArray.map((tmp, index) => (
                                  <div className=" h-64 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 duration-300  hover:scale-105"></div>
                                ))
                              : tweetTemplates.map(
                                  (template, tweetTemplateIndex) => (
                                    <TemplateCard
                                      packSlug={template.meta.pack}
                                      packView={false}
                                      modal={false}
                                      landing={true}
                                      template={template}
                                      name={template.meta.title}
                                      slug={template.meta.slug}
                                      type={template.meta.type}
                                      color={template.meta.color}
                                      icon={template.meta.icon}
                                      description={template.meta.description}
                                      locked={true}
                                      creator_name={template.meta.creator_name}
                                      creator_photo={
                                        template.meta.creator_photo
                                      }
                                      uses={template.meta.uses}
                                      example={template.meta.example}
                                    />
                                  ),
                                )
                          }
                        </div>
                      </Tab.Panel>
                    ))}
                  </div>
                  <div className="rounded-4xl pointer-events-none absolute inset-0" />
                </Tab.Panels>
              </>
            )}
          </Tab.Group>
        </div>
      </Container>
    </section>
  );
}

function undefined({ avatar1 }) {
  return (
    <div className="relative mt-8 max-w-md rounded-2xl bg-gradient-to-b from-slate-100 p-6">
      <div className="absolute top-0 left-1/2 h-[1px] w-40 -translate-x-1/2 bg-gradient-to-r from-transparent via-orange-500 to-transparent" />

      <div className="mb-4 flex flex-row items-center justify-start space-x-2">
        <Image src={avatar1} width={40} height={40} className="rounded-full" />
        <div className="flex flex-col -space-y-0.5">
          <p className="text-base font-medium text-slate-800">Sam Shore</p>
          <p className="text-xs text-slate-500">Creator of Typeshare</p>
        </div>
      </div>

      <p className="text-sm text-slate-600 ">
        I promise I'm not biased. Typeshare templates are the absolute best
        place to start. 10/10 would recommend to anyone!
      </p>
    </div>
  );
}
