import { useEffect, useState, useRef } from 'react';
import Image from 'next/image';

import { Button } from './Button';

import backgroundImage from '../../images/landing-page/background-features-coral-solid.jpg';
import backgroundImageBlue from '../../images/landing-page/background-features.jpg';
import backgroundImageGreen from '../../images/landing-page/background-features-green-solid.jpg';

import publishingBG from '../../images/landing-page/screenshots/publishing-bg.png';
import templatesBG from '../../images/landing-page/screenshots/templates-bg.png';
import analyticsBG from '../../images/landing-page/screenshots/analytics-bg.png';

import {
  ChevronRightIcon,
  EyeIcon,
  HeartIcon,
  LightningBoltIcon,
  LinkIcon,
  ShareIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { Tab, Switch } from '@headlessui/react';

import { motion } from 'framer-motion';

import clsx from 'clsx';
import Link from 'next/link';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const features = [
  {
    name: 'templates',
    image: templatesBG,
  },
  {
    name: 'Publishing',
    image: publishingBG,
  },
  {
    name: 'Analytics',
    image: analyticsBG,
  },
];

export function Hero({ login, isLoggedIn }) {
  const [tab, setTab] = useState(0);
  const [tabChanged, changeTabChanged] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [mediumEnabled, setMediumEnabled] = useState(false);
  const [linkedinEnabled, setLinkedinEnabled] = useState(false);

  const [templatesTab, setTemplatesTab] = useState(0);
  const [templatesTabChanged, setTemplatesTabChanged] = useState(false);

  const timer = useRef(null);
  const templatesTimer = useRef(null);

  useEffect(() => {
    setTemplatesTab(0);

    if (tabChanged === false) {
      timer.current = setTimeout(() => {
        if (tab === 2) {
          setTab(0);
        } else {
          setTab(tab + 1);
        }
      }, 4000);
    }

    if (tab === 1) {
      setTimeout(() => {
        setEnabled(!enabled);
      }, 1500);
      setTimeout(() => {
        setMediumEnabled(!mediumEnabled);
      }, 1900);
      setTimeout(() => {
        setLinkedinEnabled(!linkedinEnabled);
      }, 1700);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [tab]);

  useEffect(() => {
    if (!tabChanged) return;

    clearTimeout(timer.current);
  }, [tabChanged, timer]);

  useEffect(() => {
    if (templatesTabChanged === false) {
      templatesTimer.current = setTimeout(() => {
        if (templatesTab === 3) {
          setTemplatesTab(0);
        } else {
          setTemplatesTab(templatesTab + 1);
        }
      }, 1500);
    }

    return () => {
      clearTimeout(templatesTimer.current);
    };
  }, [templatesTab]);

  useEffect(() => {
    if (!templatesTabChanged) return;

    clearTimeout(templatesTimer.current);
  }, [templatesTabChanged, templatesTimer]);

  const changeTab = (val) => {
    changeTabChanged(true);
    setTab(val);
  };

  const changeTemplatesTab = (val) => {
    setTemplatesTabChanged(true);
    setTemplatesTab(val);
  };

  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  const transition = {
    duration: 1,
    //only animate once
    repeatType: 'once',
    repeat: 0,
    ease: 'easeInOut',
  };

  return (
    <div className=" max-w-screen overflow-x-hidden text-left">
      <div className="relative  mx-auto max-w-6xl border-l border-r border-dashed border-slate-200 pt-16 pb-24 lg:pt-16">
        <div className="relative z-10 mx-auto flex w-full max-w-5xl flex-col items-start justify-center px-8 pb-24 lg:px-4 xl:px-0">
          <div className="mb-4 hidden sm:block">
            <Link
              href="/templates"
              target={'_blank'}
              className="roudned-full inline-flex space-x-4 rounded-full border border-indigo-100 bg-gradient-to-br from-blue-50 to-indigo-100 p-1 pr-2"
            >
              <span className="rounded-full bg-blue-500 px-2.5 py-1 text-xs font-semibold uppercase tracking-wide text-white">
                New Release
              </span>
              <span className="inline-flex items-center space-x-1 text-sm font-medium text-blue-600">
                <span>Introducing Template Packs</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </Link>
          </div>

          <h1 className="max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
            Everything you need to{' '}
            <span
              className={`relative whitespace-nowrap ${
                tab === 0
                  ? 'inline-block bg-gradient-to-br from-rose-500 to-orange-500 bg-clip-text text-transparent'
                  : 'text-slate-900'
              }`}
            >
              {tab === 0 && (
                <svg
                  className="absolute top-2/3 left-0 h-[0.58em] w-full"
                  viewBox="0 0 113 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.path
                    fill="transparent"
                    strokeWidth="2"
                    stroke="rgb(244 63 94 / 0.5)"
                    strokeLinecap="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                    d="M1 6L62 1L41 6H112"
                  />
                </svg>
              )}
              start
            </span>{' '}
            <span
              className={`relative whitespace-nowrap ${
                tab === 1
                  ? 'bg-gradient-to-br from-blue-500 to-indigo-600 bg-clip-text text-transparent'
                  : 'text-slate-900'
              }`}
            >
              {tab === 1 && (
                <svg
                  className="absolute top-2/3 left-0 h-[0.58em] w-full"
                  viewBox="0 0 113 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.path
                    fill="transparent"
                    strokeWidth="2"
                    stroke="rgb(147 197 253 / 0.7)"
                    strokeLinecap="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                    d="M1 6L62 1L41 6H112"
                  />
                </svg>
              )}
              writing
            </span>{' '}
            <span
              className={`relative whitespace-nowrap ${
                tab === 2
                  ? 'bg-gradient-to-br from-green-500 to-yellow-300 bg-clip-text text-transparent'
                  : 'text-slate-900'
              }`}
            >
              {tab === 2 && (
                <svg
                  className="absolute top-2/3 left-0 h-[0.58em] w-full"
                  viewBox="0 0 113 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.path
                    fill="transparent"
                    strokeWidth="2"
                    stroke="rgb(34 197 94 / 0.4)"
                    strokeLinecap="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={transition}
                    d="M1 6L62 1L41 6H112"
                  />
                </svg>
              )}
              online
            </span>
            .
          </h1>
          <p className=" mt-4 max-w-2xl text-lg tracking-tightish text-slate-700">
            Templates so you never stare at a blank page again. One-click
            publishing to millions of readers. Analytics to track performance.
          </p>
          <div className="mt-6 flex justify-start gap-x-6">
            {isLoggedIn ? (
              <Button href={'/library'} click={() => login()}>
                Open Typeshare
              </Button>
            ) : (
              <Button click={() => login()}>Start Writing</Button>
            )}

            {/*  <Button
              href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              variant="outline"
            >
              <svg
                aria-hidden="true"
                className="h-3 w-3 flex-none fill-blue-600 group-active:fill-current"
              >
                <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
              </svg>
              <span className="ml-3">Watch demo</span>
            </Button> */}
          </div>
        </div>

        <motion.div
          animate={{ opacity: tab === 0 ? 1 : 0, rotate: -12 }}
          //200ms transition
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          initial={{ opacity: 0, rotate: 0 }}
          className="absolute -left-8 z-10 h-48 w-[150vw] -rotate-12 overflow-hidden lg:-left-96 lg:w-[180vw] xl:-left-[800px]"
        >
          <Image
            src={backgroundImage}
            alt=""
            width={3000}
            height={192}
            layout="fixed"
            unoptimized
          />
        </motion.div>

        <motion.div
          animate={{ opacity: tab === 1 ? 1 : 0, rotate: -12 }}
          //200ms transition
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          initial={{ opacity: 0, rotate: 0 }}
          className="absolute -left-8 z-10 h-48 w-[150vw] -rotate-12 overflow-hidden lg:-left-96 lg:w-[180vw] xl:-left-[800px]"
        >
          <Image
            src={backgroundImageBlue}
            alt=""
            width={3000}
            height={192}
            layout="fixed"
            unoptimized
          />
        </motion.div>

        <motion.div
          animate={{ opacity: tab === 2 ? 1 : 0, rotate: -12 }}
          //200ms transition
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          initial={{ opacity: 0, rotate: 0 }}
          className="absolute -left-8 z-10 h-48 w-[150vw] -rotate-12 overflow-hidden lg:-left-96 lg:w-[180vw] xl:-left-[800px]"
        >
          <Image
            src={backgroundImageGreen}
            alt=""
            width={3000}
            height={192}
            layout="fixed"
            unoptimized
          />
        </motion.div>

        <div className="relative flex w-full flex-row items-start justify-center gap-12 px-8 sm:h-[600px]">
          <div className="relative z-10 h-full w-full overflow-hidden rounded-xl border border-slate-500/10 bg-slate-50 bg-opacity-50 shadow-lg shadow-slate-900/5 backdrop-blur-xl">
            <Tab.Group as="div" className="block">
              {({ selectedIndex }) => (
                <>
                  <div className="flex w-full grid-cols-5 items-center justify-center bg-slate-100 bg-opacity-80 p-2 px-4 backdrop-blur-xl lg:grid">
                    <div className="col-span-1 hidden flex-row space-x-1 lg:flex">
                      <div className="h-2 w-2 rounded-full bg-slate-300" />
                      <div className="h-2 w-2 rounded-full bg-slate-300" />
                      <div className="h-2 w-2 rounded-full bg-slate-300" />
                    </div>

                    <Tab.List
                      className={`relative col-span-4 grid w-full grid-cols-3 items-center text-center sm:justify-center md:col-span-3`}
                    >
                      <motion.div
                        className={`absolute z-0 w-1/3 p-0  ${
                          tab === 0
                            ? 'left-0'
                            : tab === 1
                            ? 'left-1/3 -translate-x-1/2'
                            : 'right-0'
                        }`}
                        layout
                        transition={spring}
                      >
                        <div className=" h-[34px] w-full rounded-lg border border-slate-100 bg-white shadow-sm " />
                      </motion.div>

                      <Tab
                        key={'templates'}
                        onClick={() => changeTab(0)}
                        className={` ${
                          tab === 0 ? ' text-slate-900' : 'text-slate-600'
                        }  z-10 flex flex-row items-center justify-center rounded-lg border-0 border-transparent px-6 py-1 text-sm font-medium outline-none ring-0 duration-150 sm:space-x-2 `}
                      >
                        <div
                          className={`hidden h-3 w-3 rounded-sm sm:block ${
                            tab === 0 ? 'bg-orange-500' : 'bg-slate-300'
                          }`}
                        ></div>
                        <p>Templates</p>
                      </Tab>

                      <Tab
                        key={'publishing'}
                        onClick={() => changeTab(1)}
                        className={` ${
                          tab === 1 ? 'text-slate-900' : 'text-slate-600'
                        } z-10 flex flex-row items-center justify-center rounded-lg border-0 border-transparent px-6 py-1 text-sm font-medium  outline-none ring-0 duration-150 sm:space-x-2`}
                      >
                        <div
                          className={`hidden h-3 w-3 rounded-sm sm:block ${
                            tab === 1 ? 'bg-blue-500' : 'bg-slate-300'
                          }`}
                        ></div>
                        <p>Publishing</p>
                      </Tab>

                      <Tab
                        key={'analytics'}
                        onClick={() => changeTab(2)}
                        className={` ${
                          tab === 2 ? 'text-slate-900' : 'text-slate-600'
                        } z-10 flex flex-row items-center justify-center rounded-lg border-0 border-transparent px-6 py-1 text-sm font-medium  outline-none ring-0 duration-150 sm:space-x-2`}
                      >
                        <div
                          className={`hidden h-3 w-3 rounded-sm sm:block ${
                            tab === 2 ? 'bg-green-500' : 'bg-slate-300'
                          }`}
                        ></div>
                        <p>Analytics</p>
                      </Tab>
                    </Tab.List>
                  </div>

                  <Tab.Panels className="rounded-4xl relative overflow-hidden p-8 sm:p-16">
                    <div className="-mx-5 flex">
                      {features.map((feature, featureIndex) => (
                        <Tab.Panel
                          static
                          key={feature.name}
                          className={clsx(
                            'px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                            featureIndex !== tab && 'opacity-60',
                            tab === 0 && 'translate-x-0',
                            tab === 1 &&
                              '-translate-x-[105%] sm:-translate-x-[94%]',
                            tab === 2 &&
                              '-translate-x-[205%] sm:-translate-x-[calc(94%*2)]',
                          )}
                          //style={{ transform: `translateX(-${tab * 94}%)` }}
                          aria-hidden={featureIndex !== tab}
                        >
                          <div className="relative aspect-[844/428] w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                            {tab == 1 && featureIndex === tab ? (
                              <motion.div
                                animate={{ opacity: 1 }}
                                //200ms transition
                                transition={{
                                  duration: 0.5,
                                  ease: 'easeInOut',
                                  delay: 0.5,
                                }}
                                initial={{ opacity: 0, rotate: 0 }}
                                className={
                                  'absolute z-10 h-full w-full bg-slate-900/10 '
                                }
                              >
                                <motion.div
                                  className="absolute top-12 left-5 z-10 flex h-2/3 w-64 flex-col justify-between rounded-lg bg-white p-6 shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 lg:left-auto lg:right-5"
                                  animate={{ opacity: 1 }}
                                  transition={{
                                    duration: 0.5,
                                    ease: 'easeInOut',
                                    delay: 0.5,
                                  }}
                                  initial={{ opacity: 0, rotate: 0 }}
                                >
                                  <div className="flex flex-col">
                                    <div className="flex w-full flex-row items-center justify-between">
                                      <div className="flex flex-row items-center space-x-1">
                                        <div className="h-6 w-6 rounded-full bg-blue-400" />
                                        <p className="text-sm font-medium text-slate-700">
                                          Twitter
                                        </p>
                                      </div>

                                      <Switch
                                        checked={enabled}
                                        onChange={setEnabled}
                                        className={classNames(
                                          enabled
                                            ? 'bg-blue-400'
                                            : 'bg-gray-200',
                                          'relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2',
                                        )}
                                      >
                                        <span className="sr-only">
                                          Use setting
                                        </span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            enabled
                                              ? 'translate-x-4'
                                              : 'translate-x-0',
                                            'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                    <div className="my-3 h-[1px] w-full bg-gradient-to-r from-white via-slate-100 to-white" />
                                    <div className="flex w-full flex-row items-center justify-between">
                                      <div className="flex flex-row items-center space-x-1">
                                        <div className="h-6 w-6 rounded-full bg-slate-700" />
                                        <p className="text-sm font-medium text-slate-700">
                                          Medium
                                        </p>
                                      </div>

                                      <Switch
                                        checked={mediumEnabled}
                                        onChange={setMediumEnabled}
                                        className={classNames(
                                          mediumEnabled
                                            ? 'bg-slate-700'
                                            : 'bg-gray-200',
                                          'relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-slate-700 focus:ring-offset-2',
                                        )}
                                      >
                                        <span className="sr-only">
                                          Use setting
                                        </span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            mediumEnabled
                                              ? 'translate-x-4'
                                              : 'translate-x-0',
                                            'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                          )}
                                        />
                                      </Switch>
                                    </div>

                                    <div className="my-3 h-[1px] w-full bg-gradient-to-r from-white via-slate-100 to-white" />

                                    <div className="flex w-full flex-row items-center justify-between">
                                      <div className="flex flex-row items-center space-x-1">
                                        <div className="h-6 w-6 rounded-full bg-indigo-700" />
                                        <p className="text-sm font-medium text-slate-700">
                                          LinkedIn
                                        </p>
                                      </div>

                                      <Switch
                                        checked={linkedinEnabled}
                                        onChange={setLinkedinEnabled}
                                        className={classNames(
                                          linkedinEnabled
                                            ? 'bg-indigo-600'
                                            : 'bg-gray-200',
                                          'relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-offset-2',
                                        )}
                                      >
                                        <span className="sr-only">
                                          Use setting
                                        </span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            linkedinEnabled
                                              ? 'translate-x-4'
                                              : 'translate-x-0',
                                            'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="flex w-full flex-row items-stretch justify-between gap-x-2">
                                    <button className="col-span-1 w-full rounded-lg bg-orange-500 px-2 py-1 text-sm font-medium  text-white">
                                      <p>Schedule</p>
                                    </button>

                                    <button className="w-full rounded-lg bg-blue-500 px-2 py-1 text-sm font-medium text-white">
                                      <p>Publish</p>
                                    </button>
                                  </div>
                                </motion.div>
                              </motion.div>
                            ) : null}

                            {tab == 0 && featureIndex === tab ? (
                              <motion.div
                                animate={{ opacity: 1 }}
                                //200ms transition
                                transition={{
                                  duration: 0.5,
                                  ease: 'easeInOut',
                                  delay: 0.5,
                                }}
                                initial={{ opacity: 0, rotate: 0 }}
                                className={
                                  'absolute z-10 flex h-full w-full items-end justify-start rounded-xl bg-slate-900/10 pl-4 sm:justify-center  sm:pl-0'
                                }
                              >
                                <motion.div
                                  className=" z-10 grid h-3/4 w-[500px] grid-cols-6 items-start justify-start gap-6 overflow-hidden rounded-t-lg bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10"
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{
                                    duration: 0.5,
                                    ease: 'easeInOut',
                                    delay: 0.5,
                                  }}
                                  initial={{ opacity: 0, rotate: 0, y: 500 }}
                                >
                                  <div className="col-span-2 h-full bg-slate-50 p-6">
                                    <p className="whitespace-nowrap text-sm font-semibold">
                                      New Post
                                    </p>

                                    <div
                                      className={`relative mt-2 flex flex-col items-start justify-start space-y-2`}
                                    >
                                      <motion.div
                                        className={`absolute z-0 w-full p-0 ${
                                          templatesTab === 0
                                            ? 'top-0 mt-1.5 '
                                            : templatesTab === 1
                                            ? 'top-1/4 mt-1.5 -translate-y-1/4 '
                                            : templatesTab === 2
                                            ? 'top-1/2 mt-1.5 -translate-y-1/2 '
                                            : 'bottom-0 -mb-0.5'
                                        }`}
                                        layout
                                        transition={spring}
                                      >
                                        <div className=" h-[24px] w-full rounded-md bg-orange-500 " />
                                      </motion.div>

                                      <button
                                        key={'templates'}
                                        onClick={() => changeTemplatesTab(0)}
                                        className={` ${
                                          templatesTab === 0
                                            ? ' text-white'
                                            : 'text-slate-600'
                                        } z-10 flex w-full flex-row items-start justify-start space-x-2 rounded-lg border-0 border-transparent px-2 py-0.5 text-xs font-medium outline-none ring-0 duration-150`}
                                      >
                                        <p>Threads</p>
                                      </button>

                                      <button
                                        key={'publishing'}
                                        onClick={() => changeTemplatesTab(1)}
                                        className={` ${
                                          templatesTab === 1
                                            ? 'text-white'
                                            : 'text-slate-600'
                                        } z-10 flex w-full flex-row items-start justify-start space-x-2 rounded-lg border-0 border-transparent px-2 py-0.5 text-xs  font-medium outline-none ring-0 duration-150`}
                                      >
                                        <p>Essays</p>
                                      </button>

                                      <button
                                        key={'linkedin'}
                                        onClick={() => changeTemplatesTab(2)}
                                        className={` ${
                                          templatesTab === 2
                                            ? 'text-white'
                                            : 'text-slate-600'
                                        } z-10 flex w-full flex-row items-start justify-start space-x-2 rounded-lg border-0 border-transparent px-2 py-0.5 text-xs font-medium outline-none ring-0 duration-150`}
                                      >
                                        <p>LinkedIn</p>
                                      </button>
                                      <button
                                        key={'tweets'}
                                        onClick={() => changeTemplatesTab(3)}
                                        className={` ${
                                          templatesTab === 3
                                            ? 'text-white'
                                            : 'text-slate-600'
                                        } z-10 flex w-full flex-row items-start justify-start space-x-2 rounded-lg border-0 border-transparent px-2 py-0.5 text-xs font-medium outline-none ring-0 duration-150`}
                                      >
                                        <p>Tweets</p>
                                      </button>
                                    </div>
                                  </div>

                                  <div className="col-span-4 w-full pr-6 pt-6">
                                    <p className="mb-2 text-xs font-semibold">
                                      {templatesTab === 0
                                        ? 'Twitter Thread'
                                        : templatesTab === 1
                                        ? 'Atomic Essay'
                                        : templatesTab === 2
                                        ? 'LinkedIn Post'
                                        : 'Tweet'}{' '}
                                      Templates
                                    </p>

                                    <div className="grid grid-cols-3 gap-2">
                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>
                                    </div>
                                  </div>
                                </motion.div>
                              </motion.div>
                            ) : null}

                            {tab == 2 && featureIndex === tab ? (
                              <motion.div
                                animate={{ opacity: 1 }}
                                //200ms transition
                                transition={{
                                  duration: 0.5,
                                  ease: 'easeInOut',
                                  delay: 0.5,
                                }}
                                initial={{ opacity: 0, rotate: 0 }}
                                className={
                                  'absolute z-10 flex h-full w-full items-end justify-start rounded-xl bg-slate-900/10 pl-4 md:justify-center md:pl-0 '
                                }
                              >
                                <motion.div
                                  className=" z-10 flex h-3/4 w-96 flex-col items-start justify-start overflow-hidden rounded-t-lg bg-white p-6 shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10"
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{
                                    duration: 0.5,
                                    ease: 'easeInOut',
                                    delay: 0.5,
                                  }}
                                  initial={{ opacity: 0, rotate: 0, y: 500 }}
                                >
                                  <div className="w-full">
                                    <p className="mb-2 text-sm font-semibold">
                                      Post Performance
                                    </p>

                                    <div className="mb-2 flex w-full items-center justify-center rounded-md bg-green-500/10 p-2 text-xs font-medium text-green-500">
                                      <p>
                                        This post has above average engagement!
                                      </p>
                                    </div>

                                    <div className="grid grid-cols-3 gap-2">
                                      <div className="flex h-24 flex-col items-start justify-between rounded-lg border border-slate-100 bg-slate-50 p-4 duration-200 hover:scale-105">
                                        <div className="flex items-center justify-center rounded-full bg-blue-500/10 p-1.5 text-blue-500">
                                          <EyeIcon className="h-4 w-4" />
                                        </div>
                                        <p className="font-bold tracking-tight">
                                          1,572
                                        </p>
                                      </div>

                                      <div className="flex h-24 flex-col items-start justify-between rounded-lg border border-slate-100 bg-slate-50 p-4 duration-200 hover:scale-105">
                                        <div className="flex items-center justify-center rounded-full bg-red-500/10 p-1.5 text-red-500">
                                          <HeartIcon className="h-4 w-4" />
                                        </div>
                                        <p className="font-bold tracking-tight">
                                          72
                                        </p>
                                      </div>
                                      <div className="flex h-24 flex-col items-start justify-between rounded-lg border border-slate-100 bg-slate-50 p-4 duration-200 hover:scale-105">
                                        <div className="flex items-center justify-center rounded-full bg-yellow-500/10 p-1.5 text-yellow-500">
                                          <ShareIcon className="h-4 w-4" />
                                        </div>
                                        <p className="font-bold tracking-tight">
                                          24
                                        </p>
                                      </div>
                                      <div className="flex h-24 flex-col items-start justify-between rounded-lg border border-slate-100 bg-slate-50 p-4 duration-200 hover:scale-105">
                                        <div className="flex items-center justify-center rounded-full bg-indigo-500/10 p-1.5 text-indigo-500">
                                          <LinkIcon className="h-4 w-4" />
                                        </div>
                                        <p className="font-bold tracking-tight">
                                          271
                                        </p>
                                      </div>
                                      <div className="flex h-24 flex-col items-start justify-between rounded-lg border border-slate-100 bg-slate-50 p-4 duration-200 hover:scale-105">
                                        <div className="flex items-center justify-center rounded-full bg-green-500/10 p-1.5 text-green-500">
                                          <UserIcon className="h-4 w-4" />
                                        </div>
                                        <p className="font-bold tracking-tight">
                                          91
                                        </p>
                                      </div>
                                      <div className="flex h-24 flex-col items-start justify-between rounded-lg border border-slate-100 bg-slate-50 p-4 duration-200 hover:scale-105">
                                        <div className="flex items-center justify-center rounded-full bg-orange-500/10 p-1.5 text-orange-500">
                                          <LightningBoltIcon className="h-4 w-4" />
                                        </div>
                                        <p className="font-bold tracking-tight">
                                          2.3%
                                        </p>
                                      </div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>

                                      <div className="group flex h-24 items-center justify-center rounded-lg border border-slate-100 bg-slate-50 duration-200 hover:scale-105"></div>
                                    </div>
                                  </div>
                                </motion.div>
                              </motion.div>
                            ) : null}

                            <Image
                              src={feature.image}
                              alt=""
                              layout="fill"
                              sizes="52.75rem"
                            />
                          </div>
                        </Tab.Panel>
                      ))}
                    </div>
                    <div className="rounded-4xl pointer-events-none absolute inset-0" />
                  </Tab.Panels>
                </>
              )}
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
