import Image from 'next/image';
import Link from 'next/link';

export function Testimonial({
  avatar,
  text,
  theme,
  color,
  name,
  bio,
  url = '',
}) {
  return (
    <Link
      href={url}
      target={'_blank'}
      className={` hover:shadow-xl  ${
        color === 'orange'
          ? 'hover:shadow-orange-500/5'
          : 'hover:shadow-green-500/5'
      } mt-8 bg-gradient-to-b duration-200 ${
        theme === 'light' ? 'from-slate-100 hover:from-white' : 'from-slate-800'
      } relative max-w-sm rounded-2xl p-6`}
    >
      <div
        className={`w-40 bg-gradient-to-r from-transparent ${
          color === 'orange' ? 'via-orange-500' : 'via-green-500'
        } absolute top-0 left-1/2 h-[1px] -translate-x-1/2 to-transparent`}
      />

      <div className="mb-4 flex flex-row items-center justify-start space-x-2">
        <Image src={avatar} width={40} height={40} className="rounded-full" />
        <div className="flex flex-col -space-y-0.5">
          <p className="text-base font-medium text-slate-800">{name}</p>
          <p className="text-xs text-slate-500">{bio}</p>
        </div>
      </div>

      <p className="whitespace-pre-wrap text-sm text-slate-600 ">{text}</p>
    </Link>
  );
}
