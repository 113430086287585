import { Fragment } from 'react';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { Button } from './Button';
import { Container } from './Container';
import { Logo } from './Logo';
import { NavLink } from './NavLink';

function MobileNavLink({ href, children }) {
  return (
    <Popover.Button as={Link} href={href} className="block w-full p-2">
      {children}
    </Popover.Button>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="#features">Features</MobileNavLink>
            <MobileNavLink href="#testimonials">Testimonials</MobileNavLink>
            <MobileNavLink href="#pricing">Pricing</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink href="/login">Sign in</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header({ login, isLoggedIn }) {
  return (
    <header className="scroll-smooth border-b border-dashed border-gray-200 bg-white py-4 antialiased [font-feature-settings:'ss01']">
      <Container>
        <nav className="relative z-50 mx-auto grid max-w-5xl grid-cols-6">
          <div className="col-span-2 flex items-center md:gap-x-12">
            <Link href="#" aria-label="Home">
              <Logo className="h-6 w-auto" />
            </Link>
          </div>
          <div className="col-span-2 my-auto hidden items-center justify-center md:flex md:gap-x-6">
            {/*  <NavLink href="#features">Features</NavLink>
              <NavLink href="#testimonials">Templates</NavLink>
              <NavLink href="#pricing">Pricing</NavLink> */}
          </div>
          <div className="col-span-4 flex items-center justify-end gap-x-2  md:col-span-2">
            <div className={`${isLoggedIn ? 'hidden' : 'block'}`}>
              <NavLink click={() => login()}>Sign in</NavLink>
            </div>
            {isLoggedIn ? (
              <Button href={'/library'} color="blue">
                <span>Open Typeshare</span>
              </Button>
            ) : (
              <Button click={() => login()} color="blue">
                <span>Get Started</span>
              </Button>
            )}
            <div className="-mr-1 hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
