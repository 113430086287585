import { useEffect, useState } from 'react';
import { Container } from './Container';
import { LightningBoltIcon, PencilAltIcon } from '@heroicons/react/solid';

export function TopTwoColumn() {
  return (
    <section
      id="publishing"
      aria-label="Write once. Publish everywhere."
      className="relative overflow-hidden bg-slate-900"
    >
      <Container className="relative flex max-w-6xl flex-col items-start justify-start border-x border-dashed border-gray-800 py-24 md:py-32 lg:py-48 ">
        <div className="flex max-w-xl flex-col md:text-left xl:max-w-3xl">
          <p className="mb-6 font-sans text-base font-semibold tracking-tight text-blue-500">
            Publishing
          </p>

          <h2 className="font-display text-3xl font-medium tracking-tighter text-white sm:text-4xl md:text-5xl">
            Write once. Publish everywhere.
          </h2>
          <p className="mt-6 max-w-xl text-lg tracking-tightish text-slate-400">
            Write in our simple editor and publish natively to Twitter,
            LinkedIn, Medium, and your very own Typeshare Social Blog.
          </p>
        </div>

        <div className="my-16 grid w-full grid-cols-1 gap-16 md:grid-cols-2">
          <div className="flex flex-col">
            <h3 className="font-display text-2xl font-medium text-blue-500">
              Publish everywhere
            </h3>

            <p className="mt-4 mb-12 max-w-xl text-base tracking-tight text-slate-400">
              With Typeshare, you can publishing your writing directly to
              Twitter, LinkedIn, and Medium without having to struggle with
              copy/paste.
            </p>

            <div className="flex flex-col">
              <div className="flex flex-col space-y-2">
                <div className="max-w-min rounded-full bg-blue-500/10 p-2">
                  <LightningBoltIcon className="h-5 w-5 text-blue-500" />
                </div>

                <p className="text-base font-medium text-white">Live preview</p>
                <p className="max-w-sm text-sm text-slate-400">
                  Get a live preview of your writing so you can format your
                  content perfectly for the platform you are publishing to.
                </p>
              </div>

              <div className="mt-10 flex flex-col space-y-2">
                <div className="max-w-min rounded-full bg-blue-500/10 p-2">
                  <PencilAltIcon className="h-5 w-5 text-blue-500" />
                </div>

                <p className="text-base font-medium text-white">
                  Platform specific edits
                </p>
                <p className="max-w-sm text-sm text-slate-400">
                  Make content or formatting changes specific to the platform
                  you are publishing to.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <h3 className="font-display text-2xl font-medium text-blue-500">
              Share natively
            </h3>

            <p className="mt-4 mb-6 max-w-xl text-base tracking-tight text-slate-400">
              Other platforms' just share the link to your post. But, with
              Typeshare native publishing, you'll get 10x the engagement.
            </p>

            <div className="relative flex flex-col space-y-6">
              <div className="flex flex-row items-center justify-between space-x-6 overflow-hidden rounded-xl bg-indigo-600 pr-6 duration-200 hover:scale-105">
                <div className="flex flex-row items-center space-x-6">
                  <div className="flex items-center justify-center bg-indigo-500 p-6">
                    <svg
                      className={'h-6 w-6 fill-current text-white'}
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                    </svg>
                  </div>

                  <div className="flex flex-col space-y-1">
                    <p className="test-sm font-medium text-white">
                      Publish to LinkedIn
                    </p>
                    <div className="flex flex-row space-x-2">
                      <div className="rounded-full bg-white px-3 py-1 text-xs font-semibold text-indigo-600">
                        Post
                      </div>
                    </div>
                  </div>
                </div>
                {/*  <p className='text-white font-medium text-sm hidden sm:flex lg:flex'>230M Readers</p> */}
              </div>

              <div className="flex flex-row items-center justify-between space-x-6 overflow-hidden rounded-xl bg-blue-500 pr-6 duration-200 hover:scale-105">
                <div className="flex flex-row items-center space-x-6">
                  <div className="flex items-center justify-center bg-blue-400 p-6">
                    <svg
                      className={'h-6 w-6 fill-current text-white'}
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                    </svg>
                  </div>

                  <div className="flex flex-col space-y-2">
                    <p className="test-sm font-medium text-white">
                      Publish to Twitter
                    </p>
                    <div className="flex flex-row space-x-2">
                      <div className="rounded-full bg-orange-500 px-3 py-1 text-xs font-semibold text-white">
                        Tweet
                      </div>
                      <div className="rounded-full bg-indigo-500 px-3 py-1 text-xs font-semibold text-white">
                        Thread
                      </div>
                      <div className="rounded-full bg-green-500 px-3 py-1 text-xs font-semibold text-white">
                        Image
                      </div>
                    </div>
                  </div>
                </div>
                {/*   <p className='text-white font-medium text-sm hidden sm:flex lg:flex'>180M Readers</p> */}
              </div>

              <div className="flex flex-row items-center justify-between space-x-6 overflow-hidden rounded-xl bg-slate-700 pr-6 duration-200 hover:scale-105">
                <div className="flex flex-row items-center space-x-6">
                  <div className="flex items-center justify-center bg-slate-600 p-6">
                    <svg
                      className={'h-6 w-6 fill-current text-white'}
                      viewBox="0 0 30 30"
                    >
                      <path d="M8.5 7A8.5 8.5 0 108.5 24 8.5 8.5 0 108.5 7zM22 8A4 7.5 0 1022 23 4 7.5 0 1022 8zM28.5 9A1.5 6.5 0 1028.5 22 1.5 6.5 0 1028.5 9z" />
                    </svg>
                  </div>

                  <div className="flex flex-col space-y-2">
                    <p className="test-sm font-medium text-white">
                      Publish to Medium
                    </p>
                    <div className="flex flex-row space-x-2">
                      <div className="rounded-full bg-blue-500 px-3 py-1 text-xs font-semibold text-white">
                        Story
                      </div>
                    </div>
                  </div>
                </div>
                {/*  <p className='text-white font-medium text-sm hidden sm:flex lg:flex'>340M Readers</p> */}
              </div>

              <p className="mx-auto text-center font-display text-xl font-medium text-white">
                Publish directly to 740 Million readers
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
