import Head from 'next/head';

import { useEffect, useState } from 'react';
import { CallToAction } from '../components/LandingPage/CallToAction';
import { Footer } from '../components/LandingPage/Footer';
import { GridFeatures } from '../components/LandingPage/GridFeatures';
import { GridInfinite } from '../components/LandingPage/GridInfinite';
import { Header } from '../components/LandingPage/Header';
import { Hero } from '../components/LandingPage/Hero';
import { SideBySide } from '../components/LandingPage/SideBySide';
import { SideBySideCarosel } from '../components/LandingPage/SideBySideCarosel';
import { TopTwoColumn } from '../components/LandingPage/TopTwoColumn';
import LogInModal from '../components/NewModals/log-in';

import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

export const getStaticProps = async () => {
  try {
    const firebaseConfig = {
      apiKey: 'AIzaSyA64Cm0bTMY9Rinvha_z3ENAUhkJssFCO8',
      authDomain: 'auth.typeshare.co',
      projectId: 'typeshare-2027d',
      storageBucket: 'typeshare-2027d.appspot.com',
      databaseURL: 'https://typeshare-2027d-default-rtdb.firebaseio.com/',
      messagingSenderId: '305996917305',
      appId: '1:305996917305:web:704a11e8f876840e87bbe8',
      measurementId: 'G-LX68JP4ZZK',
    };

    //TEST APP

    const firebaseConfigStaging = {
      apiKey: 'AIzaSyD5pDmQa1lIAYiW1qd7Swez2gaXG-ggWtc',
      authDomain: 'test-88a5a.firebaseapp.com',
      projectId: 'test-88a5a',
      storageBucket: 'test-88a5a.appspot.com',
      messagingSenderId: '952460692987',
      appId: '1:952460692987:web:0b0eb7f65c0e3f6767cf5e',
    };

    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
      }
    } else {
      if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfigStaging);
      }
    }

    async function getTemplatesByType(type, count) {
      const baseRef = firebase.database().ref('templates2');
      const activeType = await baseRef
        .child('types')
        .child(type)
        .limitToLast(count)
        .once('value');

      const templateIDs = Object.keys(activeType.val().templates);

      const templates = await Promise.all(
        templateIDs.map(async (templateID) => {
          const ref = baseRef
            .child('templates')
            .child(templateID)
            .child('meta');
          const snapshot = await ref.once('value');
          var format = {
            meta: snapshot.val(),
          };
          return format;
        }),
      );

      //get creator's name and photo from firebase and update templates
      const creators = await Promise.all(
        templates.map(async (template) => {
          const ref = baseRef.child('creators').child(template.meta.creator);
          const snapshot = await ref.once('value');
          return snapshot.val();
        }),
      );

      templates.forEach((template, index) => {
        template.meta.creator_name = creators[index].name;
        template.meta.creator_photo = creators[index].photo;
      });

      return templates;
    }

    const essays = await getTemplatesByType('atomic-essay', 10);
    const threads = await getTemplatesByType('thread', 10);
    const linkedin = await getTemplatesByType('linkedin-post', 10);
    const tweets = await getTemplatesByType('tweet', 10);

    //combine all templates into one array and randomize
    const allTemplates = [...essays, ...threads, ...linkedin, ...tweets];
    const shuffledTemplates = allTemplates.sort(() => Math.random() - 0.5);

    return {
      props: {
        exists: true,
        templates: {
          essays,
          threads,
          linkedin,
          tweets,
        },
        shuffledTemplates,
      },
      revalidate: 1,
    };
  } catch (error) {
    console.log(error);

    return {
      props: {
        exists: false,
      },
      revalidate: 1,
    };
  }
};

export default function Home({ templates, shuffledTemplates }) {
  const router = useRouter();

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (router.query && isLoggedIn) {
      var offerParam = router.query.offer ? router.query.offer : null;
      //to redirect logged in users with no offer, change null to redirect
      offerParam
        ? router.push({ pathname: `/library`, query: { offer: offerParam } })
        : router.push({ pathname: `/library` });
    }
  }, [isLoggedIn, router.query]);

  useEffect(() => {
    if (isLoggedIn) {
      router.push({ pathname: `/library` });
    }
  }, [isLoggedIn]);

  return (
    <>
      <Head>
        <title>Typeshare | Everything you need to start writing online.</title>
        <meta
          name="description"
          content="Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@typeshare_co" />
        <meta
          name="twitter:title"
          content="Typeshare | Everything you need to start writing online."
        />
        <meta
          name="twitter:description"
          content={
            'Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance.'
          }
        />
        <meta name="twitter:creator" content="@samjshore" />
        <meta name="twitter:image" content="https://typeshare.co/ts2-og.png" />
      </Head>
      <NextSeo
        title={'Typeshare'}
        titleTemplate={'%s | Everything you need to start writing online.'}
        description={
          'Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance.'
        }
        additionalLinkTags={[
          {
            rel: 'icon',
            href: '/favicon.ico',
          },
        ]}
        openGraph={{
          type: 'website',
          title: 'Everything you need to start writing online.',
          description:
            'Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance.',
          url: `https://typeshare.co/`,
          images: [
            {
              url: '/ts2-og.png',
              width: 1200,
              height: 630,
              alt: 'Og Image Alt',
            },
          ],
        }}
      />
      <Header isLoggedIn={isLoggedIn} login={() => setLoginModalOpen(true)} />
      <main className="scroll-smooth bg-white antialiased [font-feature-settings:'ss01']">
        <LogInModal
          isOpen={loginModalOpen}
          setOpen={setLoginModalOpen}
          closable={true}
        />
        <Hero isLoggedIn={isLoggedIn} login={() => setLoginModalOpen(true)} />
        <SideBySide templates={templates} />
        <TopTwoColumn />
        <SideBySideCarosel />
        <GridInfinite templates={shuffledTemplates} />
        <GridFeatures />
        <CallToAction
          isLoggedIn={isLoggedIn}
          login={() => setLoginModalOpen(true)}
        />
      </main>
      <Footer />
    </>
  );
}
