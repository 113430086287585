import Image from 'next/image';

import { Button } from './Button';
import { Container } from './Container';
import backgroundImage from '../../images/landing-page/background-features-coral-solid.jpg';
import backgroundImageBlue from '../../images/landing-page/background-features.jpg';
import backgroundImageGreen from '../../images/landing-page/background-features-green-solid.jpg';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export function CallToAction({ login, isLoggedIn }) {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (tab === 2) {
        setTab(0);
      } else {
        setTab(tab + 1);
      }
    }, 4000);
  }, [tab]);

  return (
    <section
      id="get-started-today"
      className="relative flex h-96 items-center justify-center overflow-hidden"
    >
      <motion.div
        animate={{ opacity: tab === 0 ? 1 : 0 }}
        //200ms transition
        transition={{ duration: 3, ease: 'easeInOut' }}
        initial={{ opacity: 0, rotate: 0 }}
        className="absolute -left-8 h-96 w-[150vw] overflow-hidden lg:-left-96"
      >
        <Image
          src={backgroundImage}
          alt=""
          width={3000}
          height={384}
          layout="fixed"
          unoptimized
        />
      </motion.div>

      <motion.div
        animate={{ opacity: tab === 1 ? 1 : 0 }}
        //200ms transition
        transition={{ duration: 3, ease: 'easeInOut' }}
        initial={{ opacity: 0, rotate: 0 }}
        className="absolute -left-8 h-96 w-[150vw] overflow-hidden lg:-left-96"
      >
        <Image
          src={backgroundImageBlue}
          alt=""
          width={3000}
          height={384}
          layout="fixed"
          unoptimized
        />
      </motion.div>

      <motion.div
        animate={{ opacity: tab === 2 ? 1 : 0 }}
        //200ms transition
        transition={{ duration: 3, ease: 'easeInOut' }}
        initial={{ opacity: 0, rotate: 0 }}
        className="absolute -left-8 h-96 w-[150vw] overflow-hidden lg:-left-96"
      >
        <Image
          src={backgroundImageGreen}
          alt=""
          width={3000}
          height={384}
          layout="fixed"
          unoptimized
        />
      </motion.div>

      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-5xl">
            Get started today
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Ready to start writing online? Get started today.
          </p>
          {isLoggedIn ? (
            <Button href={'/library'} color="white" className="mt-10">
              Open Typeshare
            </Button>
          ) : (
            <Button click={() => login()} color="white" className="mt-10">
              Start Writing for Free
            </Button>
          )}
        </div>
      </Container>
    </section>
  );
}
